import React, { FunctionComponent } from "react";
import MaybeClassProps from "../../components/MaybeClassProps.type";
import Strong from "../../components/Strong";
import { ReactComponent as CheckIcon } from "../../images/icons/check.svg";

const RiskFactorsTable: FunctionComponent<MaybeClassProps> = ({
  className,
}) => {
  return (
    <table className={className}>
      <tbody>
        <tr>
          <th></th>
          <th className="bg-grey-very-light h-2 md:h-4 w-16"></th>
          <th className="w-2 md:w-4"></th>
          <th className="bg-grey-very-light w-16"></th>
          <th className="w-0 sm:w-4 hidden sm:table-cell"></th>
        </tr>
        <tr className="bg-red text-white">
          <th className="text-left text-xs md:text-base align-top h-12 pl-4 py-4">
            Host Factors
          </th>
          <th className="text-xs md:text-base align-top h-12 px-2 py-4 bg-red-grey md:bg-red">
            Cirrhosis<sup>13</sup>
          </th>
          <th className="w-2 md:w-4"></th>
          <th className="text-xs md:text-base align-top h-12 px-6 py-4 bg-red-grey md:bg-red">
            HCC<sup>13,14</sup>
          </th>
          <th className="w-0 sm:w-4 hidden sm:table-cell"></th>
        </tr>
        <tr className="border-bottom-grey-light">
          <td className="text-xs align-top text-black text-left px-2 py-3">
            <Strong>&gt;40 years of age</Strong>
          </td>
          <td className="text-center bg-grey-very-light">
            <CheckIcon className="inline" />
          </td>
          <td></td>
          <td className="text-center bg-grey-very-light">
            <CheckIcon className="inline" />
          </td>
          <td className="w-0 sm:w-4 hidden sm:table-cell"></td>
        </tr>
        <tr className="border-bottom-grey-light">
          <td className="text-xs align-top text-black text-left px-2 py-3">
            <Strong>Male gender</Strong>
          </td>
          <td className="text-center bg-grey-very-light">
            <CheckIcon className="inline" />
          </td>
          <td></td>
          <td className="text-center bg-grey-very-light">
            <CheckIcon className="inline" />
          </td>
          <td className="w-0 sm:w-4 hidden sm:table-cell"></td>
        </tr>
        <tr className="border-bottom-grey-light">
          <td className="text-xs align-top text-black text-left px-2 py-3">
            <Strong>Immune compromised</Strong>
          </td>
          <td className="text-center bg-grey-very-light">
            <CheckIcon className="inline" />
          </td>
          <td></td>
          <td className="text-center bg-grey-very-light">
            <CheckIcon className="inline" />
          </td>
          <td className="w-0 sm:w-4 hidden sm:table-cell"></td>
        </tr>
        <tr className="border-bottom-grey-light">
          <td className="text-xs align-top text-black text-left px-2 py-3">
            <Strong>Family history of HCC</Strong>
          </td>
          <td className="text-center bg-grey-very-light"></td>
          <td></td>
          <td className="text-center bg-grey-very-light">
            <CheckIcon className="inline" />
          </td>
          <td className="w-0 sm:w-4 hidden sm:table-cell"></td>
        </tr>
        <tr>
          <td className="text-xs align-top text-black text-left px-2 py-3">
            <Strong>Born in Sub-Saharan Africa or Asia</Strong>
          </td>
          <td className="text-center bg-grey-very-light"></td>
          <td></td>
          <td className="text-center bg-grey-very-light">
            <CheckIcon className="inline" />
          </td>
          <td className="w-0 sm:w-4 hidden sm:table-cell"></td>
        </tr>
        <tr className="bg-red text-white">
          <th className="text-left text-xs md:text-base align-top h-12 pl-4 py-4">
            Viral / Disease Factors
          </th>
          <th className="text-xs md:text-base align-top h-12 px-2 py-4 bg-red-grey md:bg-red">
            Cirrhosis<sup>13</sup>
          </th>
          <th></th>
          <th className="text-xs md:text-base align-top h-12 px-2 py-4 bg-red-grey md:bg-red">
            HCC<sup>13,14</sup>
          </th>
          <th className="w-0 sm:w-4 hidden sm:table-cell"></th>
        </tr>
        <tr className="border-bottom-grey-light">
          <td className="text-xs align-top text-black text-left px-2 py-3">
            <Strong>High viral load (&gt;2000 IU/mL)</Strong>
          </td>
          <td className="text-center bg-grey-very-light">
            <CheckIcon className="inline" />
          </td>
          <td></td>
          <td className="text-center bg-grey-very-light">
            <CheckIcon className="inline" />
          </td>
          <td className="w-0 sm:w-4 hidden sm:table-cell"></td>
        </tr>
        <tr className="border-bottom-grey-light">
          <td className="text-xs align-top text-black text-left px-2 py-3">
            <Strong>Elevated ALT</Strong>
          </td>
          <td className="text-center bg-grey-very-light">
            <CheckIcon className="inline" />
          </td>
          <td></td>
          <td className="text-center bg-grey-very-light">
            <CheckIcon className="inline" />
          </td>
          <td className="w-0 sm:w-4 hidden sm:table-cell"></td>
        </tr>
        <tr className="border-bottom-grey-light">
          <td className="text-xs align-top text-black text-left px-2 py-3">
            <Strong>High serum AFP</Strong>
          </td>
          <td className="text-center bg-grey-very-light"></td>
          <td></td>
          <td className="text-center bg-grey-very-light">
            <CheckIcon className="inline" />
          </td>
          <td className="w-0 sm:w-4 hidden sm:table-cell"></td>
        </tr>
        <tr className="border-bottom-grey-light">
          <td className="text-xs align-top text-black text-left px-2 py-3">
            <Strong>Prolonged time to HBeAg seroconversion</Strong>
          </td>
          <td className="text-center bg-grey-very-light">
            <CheckIcon className="inline" />
          </td>
          <td></td>
          <td className="text-center bg-grey-very-light">
            <CheckIcon className="inline" />
          </td>
          <td className="w-0 sm:w-4 hidden sm:table-cell"></td>
        </tr>
        <tr className="border-bottom-grey-light">
          <td className="text-xs align-top text-black text-left px-2 py-3">
            <Strong>Development of HBeAg-negative CHB</Strong>
          </td>
          <td className="text-center bg-grey-very-light">
            <CheckIcon className="inline" />
          </td>
          <td></td>
          <td className="text-center bg-grey-very-light">
            <CheckIcon className="inline" />
          </td>
          <td className="w-0 sm:w-4 hidden sm:table-cell"></td>
        </tr>
        <tr className="border-bottom-grey-light">
          <td className="text-xs align-top text-black text-left px-2 py-3">
            <Strong>Genotype C</Strong>
          </td>
          <td className="text-center bg-grey-very-light">
            <CheckIcon className="inline" />
          </td>
          <td></td>
          <td className="text-center bg-grey-very-light">
            <CheckIcon className="inline" />
          </td>
          <td className="w-0 sm:w-4 hidden sm:table-cell"></td>
        </tr>
        <tr className="border-bottom-grey-light">
          <td className="text-xs align-top text-black text-left px-2 py-3">
            <Strong>BCP or PC mutations</Strong>
          </td>
          <td className="text-center bg-grey-very-light"></td>
          <td></td>
          <td className="text-center bg-grey-very-light">
            <CheckIcon className="inline" />
          </td>
          <td className="w-0 sm:w-4 hidden sm:table-cell"></td>
        </tr>
        <tr>
          <td className="text-xs align-top text-black text-left px-2 py-3">
            <Strong>Presence of cirrhosis</Strong>
          </td>
          <td className="text-center bg-grey-very-light"></td>
          <td></td>
          <td className="text-center bg-grey-very-light">
            <CheckIcon className="inline" />
          </td>
          <td className="w-0 sm:w-4 hidden sm:table-cell"></td>
        </tr>
        <tr className="bg-red text-white">
          <th className="text-left text-xs md:text-base align-top h-12 pl-4 py-4">
            Environmental
          </th>
          <th className="text-xs md:text-base align-top h-12 px-2 py-4 bg-red-grey md:bg-red">
            Cirrhosis<sup>13</sup>
          </th>
          <th></th>
          <th className="text-xs md:text-base align-top h-12 px-2 py-4 bg-red-grey md:bg-red">
            HCC<sup>13,14</sup>
          </th>
          <th className="w-0 sm:w-4 hidden sm:table-cell"></th>
        </tr>

        <tr className="border-bottom-grey-light">
          <td className="text-xs align-top text-black text-left px-2 py-3">
            <Strong>HCV, HIV, or HDV coinfections</Strong>
          </td>
          <td className="text-center bg-grey-very-light">
            <CheckIcon className="inline" />
          </td>
          <td></td>
          <td className="text-center bg-grey-very-light">
            <CheckIcon className="inline" />
          </td>
          <td className="w-0 sm:w-4 hidden sm:table-cell"></td>
        </tr>
        <tr className="border-bottom-grey-light">
          <td className="text-xs align-top text-black text-left px-2 py-3">
            <Strong>Heavy alcohol use</Strong>
          </td>
          <td className="text-center bg-grey-very-light">
            <CheckIcon className="inline" />
          </td>
          <td></td>
          <td className="text-center bg-grey-very-light">
            <CheckIcon className="inline" />
          </td>
          <td className="w-0 sm:w-4 hidden sm:table-cell"></td>
        </tr>
        <tr className="border-bottom-grey-light">
          <td className="text-xs align-top text-black text-left px-2 py-3">
            <Strong>Metabolic syndrome (obesity, diabetes)</Strong>
          </td>
          <td className="text-center bg-grey-very-light">
            <CheckIcon className="inline" />
          </td>
          <td></td>
          <td className="text-center bg-grey-very-light">
            <CheckIcon className="inline" />
          </td>
          <td className="w-0 sm:w-4 hidden sm:table-cell"></td>
        </tr>
        <tr className="border-bottom-grey-light">
          <td className="text-xs align-top text-black text-left px-2 py-3">
            <Strong>Alfatoxin</Strong>
          </td>
          <td className="text-center bg-grey-very-light"></td>
          <td></td>
          <td className="text-center bg-grey-very-light">
            <CheckIcon className="inline" />
          </td>
          <td className="w-0 sm:w-4 hidden sm:table-cell"></td>
        </tr>
        <tr>
          <td className="text-xs align-top text-black text-left px-2 py-3">
            <Strong>Smoking</Strong>
          </td>
          <td className="text-center bg-grey-very-light"></td>
          <td></td>
          <td className="text-center bg-grey-very-light">
            <CheckIcon className="inline" />
          </td>
          <td className="w-0 sm:w-4 hidden sm:table-cell"></td>
        </tr>
      </tbody>
    </table>
  );
};
export default RiskFactorsTable;
