import React, { FunctionComponent } from "react";
import { mergeClassNames } from "../../helpers";
import Strong from "../Strong";
import { ReactComponent as ClipboardIcon } from "./clipboard.svg";
import { ReactComponent as ClipboardIconWhite } from "./clipboard-white.svg";

type Props = {
  headline: string | JSX.Element;
  content: string | JSX.Element;
  className?: string;
  iconColor?: string;
};
const ClipboardCard: FunctionComponent<Props> = ({
  headline,
  content,
  className,
  iconColor,
}) => {
  return (
    <div
      className={mergeClassNames(
        "rounded-lg px-4 py-6 z-10 my-4 md:my-0 bg-grey",
        className
      )}
    >
      {iconColor === "white" ? <ClipboardIconWhite /> : <ClipboardIcon />}
      <Strong color="black-dark" size="lg" className="pt-2 block">
        {headline}
      </Strong>
      <div className="text-black">{content}</div>
    </div>
  );
};
export default ClipboardCard;
