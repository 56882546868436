import React, { FunctionComponent } from "react";

import { mergeClassNames } from "../helpers";

type Props = {
  className?: string;
  color?: string;
};

const HorizontalRule: FunctionComponent<Props> = ({
  className,
  color,
  ...rest
}) => {
  return (
    <hr
      className={mergeClassNames(
        "border-t",
        "border-" + (color ? color : "black"),
        className
      )}
      {...rest}
    />
  );
};

export default HorizontalRule;
