import React, { useEffect } from "react";
import Layout from "../../components/Layout";
import SEO from "../../components/SEO";
import HeroImage from "../../components/HeroImage/HeroImage";
import Slab from "../../components/Slab";
import Subheading from "../../components/Subheading/Subheading";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import heroBox from "../../images/hepatitis-b-risk/screening/hero-box.svg";
import heroBoxWithoutAnimation from "../../images/hepatitis-b-risk/screening/hero-box-without-animation.svg";
import HorizontalRule from "../../components/HorizontalRule";
import Footnotes from "../../components/Footnotes";
import ClipboardCard from "../../components/Card/ClipboardCard";
import Link from "../../components/Link/Link";
import NextPage from "../../components/Link/NextPage";

import Strong from "../../components/Strong";
import DonutGraph from "../../components/DonutGraph/DonutGraph";
import { ReactComponent as EmsIcon } from "../../images/icons/ems.svg";
import Paragraph from "../../components/Paragraph";

import BlockQuote from "../../components/BlockQuote/BlockQuote";
import AssetCardGroup from "../../components/AssetCard/AssetCardGroup";

import References from "../../components/References/References";
import ReferenceItem from "../../components/References/ReferenceItem";
import ScreeningGuidelines from "../../partials/AssetCards/ScreeningGuidelines";
import RiskFactorsTable from "../../partials/Tables/RiskFactorsTable";
import AbbrList from "../../components/Abbr/AbbrList";
import Abbr from "../../components/Abbr/Abbr";
import tracker from "../../tracker";
import ImageWrapper from "../../components/ImageWrapper";
import Parallax from "../../components/Parallax/Parallax";

const WhyScreen = () => {
  const data = useStaticQuery(graphql`
    query {
      hero: file(relativePath: { eq: "hepatitis-b-risk/screening/hero.jpg" }) {
        ...HeroQuery
      }
      pill2Of3: file(
        relativePath: { eq: "hepatitis-b-risk/screening/pill-2-out-of-3.png" }
      ) {
        ...TextQuery
      }
      pillAsymptomatic: file(
        relativePath: { eq: "hepatitis-b-risk/screening/pill-asymptomatic.png" }
      ) {
        ...TextQuery
      }
      slabWoman: file(
        relativePath: { eq: "hepatitis-b-risk/screening/2-1-woman.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 262) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      calloutDesktop: file(
        relativePath: {
          eq: "hepatitis-b-risk/screening/callout-complications-desktop.png"
        }
      ) {
        ...TextQuery
      }
      calloutMobile: file(
        relativePath: {
          eq: "hepatitis-b-risk/screening/callout-complications-mobile.png"
        }
      ) {
        ...TextQuery
      }
      progressionChartHorizontal: file(
        relativePath: {
          eq: "hepatitis-b-risk/screening/HBV-progression-chart-desktop.png"
        }
      ) {
        ...HeroQuery
      }
      progressionChartVertical: file(
        relativePath: {
          eq: "hepatitis-b-risk/screening/HBV-progression-chart-mobile.png"
        }
      ) {
        ...HeroQuery
      }
      footerMan: file(relativePath: { eq: "footer-man.png" }) {
        childImageSharp {
          fluid(maxWidth: 308) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      chbScreeningGuidelines: file(
        relativePath: {
          eq: "hepatitis-b-resources/chronic-hepatitis-b-screening-brochure.pdf"
        }
      ) {
        publicURL
      }
      page: trackingJson(id: { eq: "hepatitis-b-risk/screening" }) {
        subject
      }
      downloadChbGuidelines: trackingJson(id: { eq: "downloadChbGuidelines" }) {
        ...TrackingQuery
      }
      viewCdcRecommendations: trackingJson(
        id: { eq: "viewCdcRecommendations" }
      ) {
        ...TrackingQuery
      }
      seeScreeningProtocols: trackingJson(id: { eq: "seeScreeningProtocols" }) {
        ...TrackingQuery
      }
      howToScreenPatients: trackingJson(id: { eq: "howToScreenPatients" }) {
        ...TrackingQuery
      }
      downloadTheGuide: trackingJson(id: { eq: "downloadTheGuide" }) {
        ...TrackingQuery
      }
    }
  `);
  useEffect(() => {
    window.dataLayer.push(data.page);
  }, []);

  return (
    <Layout>
      <SEO
        title="Chronic Hepatitis B Screening"
        description="Learn why hepatitis B screening is so important to reduce transmission and help patients avoid disease progression."
      />
      <HeroImage
        headLine="Why screen your at-risk patients for HBV?"
        fluidQuery={data.hero.childImageSharp.fluid}
        boxSrc={heroBox}
        boxSrcWithoutAnimation={heroBoxWithoutAnimation}
        imagePosition={{ desktop: { x: 60, y: 20 }, mobile: { x: 68, y: 50 } }}
      />
      <div className="md:-mt-16">
        <div className="container relative">
          <div className="flex flex-col md:flex-row -mx-4">
            <ClipboardCard
              className="md:w-1/3 mx-4"
              headline={
                <span>
                  To identify and diagnose chronically infected patients
                  <sup>1</sup>
                </span>
              }
              content={
                <span>
                  who may need treatment&mdash;most people with chronic
                  hepatitis B are unaware of their infection<sup>2</sup>
                </span>
              }
            />
            <ClipboardCard
              className="md:w-1/3 mx-4"
              headline="To reduce transmission of HBV infection"
              content="to others, including household members and sexual partners"
            />
            <ClipboardCard
              className="md:w-1/3 mx-4"
              headline="To help patients avoid serious complications"
              content={
                <span>
                  such as fibrosis, cirrhosis, and HCC through appropriate
                  medical management<sup>3</sup>
                </span>
              }
            />
          </div>
          <AbbrList>
            <Abbr term="CHB" title="chronic hepatitis B" />
            <Abbr term="HBV" title="hepatitis B virus" />
            <Abbr term="HCC" title="heptatocellular carcinoma." />
          </AbbrList>
          <div className="pt-4 pb-2">
            <a
              href={data.chbScreeningGuidelines.publicURL}
              className="font-bold antialiased text-green underline"
              download
              onClick={tracker(data.downloadChbGuidelines)}
            >
              Download <em>CHB Screening &amp; Management Guidelines</em>
            </a>
          </div>
        </div>
      </div>
      <Slab paddingBottom="pb-0">
        <Subheading>Identify your patients with chronic hep B</Subheading>
        <Paragraph>
          In the US, the approximately <Strong>2.2 million</Strong> people
          living with chronic hep&nbsp;B go largely undiagnosed and untreated.
          <sup>4,5</sup>
        </Paragraph>
        <div className="flex flex-col md:flex-row items-center pt-4">
          <div className="w-full md:w-2/5">
            <ImageWrapper left maxWidth={343}>
              <Img
                fluid={data.pill2Of3.childImageSharp.fluid}
                alt="About 2 out of 3 are unaware"
                className="my-8"
              />
              <Paragraph className="text-center pb-8 ">
                About 2 out of 3 people with CHB in the US are unaware of their
                infection<sup>2</sup>
              </Paragraph>
            </ImageWrapper>
          </div>
          <div className="w-full md:w-3/5 md:pl-8">
            <div className="flex items-center">
              <DonutGraph
                percent={0.7}
                prefix="~"
                className="w-1/3 p-4"
                alt="Donut graph showing about 70%"
                maxWidth={135}
              />
              <Strong color="red" size="lg" className="w-2/3 md:text-xl">
                of people with CHB are undiagnosed<sup>2</sup>
              </Strong>
            </div>
            <div className="flex items-center">
              <DonutGraph
                percent={0.95}
                prefix="&gt;"
                className="w-1/3 p-4"
                alt="Donut graph showing more than 95%"
                maxWidth={135}
              />
              <Strong color="red" size="lg" className="w-2/3 md:text-xl">
                of chronically infected people are untreated<sup>2,a</sup>
              </Strong>
            </div>
            <Footnotes className="md:ml-4 lg:ml-8">
              <sup>a</sup>Overall, &lt;10% of those who meet medical eligibility
              for HBV treatment in the US receive medication.<sup>2</sup>
            </Footnotes>
          </div>
        </div>
        <HorizontalRule className="mt-16" />
      </Slab>
      <Slab>
        <div className="flex flex-col md:flex-row">
          <div className="w-full md:w-1/2">
            <h3 className="font-bold antialiased">
              Do you have patients with undiagnosed CHB?
            </h3>
            <div className="text-black text-sm pt-3">
              CHB is often asymptomatic and patients can be without symptoms for
              many years.<sup>6</sup>
            </div>
            <ImageWrapper left maxWidth={304}>
              <Img
                fluid={data.pillAsymptomatic.childImageSharp.fluid}
                alt="CHB patients can be asymptomatic for decades"
                className="my-8"
              />
            </ImageWrapper>
          </div>
          <div className="w-full md:w-1/2 self-end h-48 md:h-auto">
            <div className="px-12 z:0 -mb-32 md:-mb-8 lg:px-24">
              <Parallax speed={-1} percentage={0.1}>
                <ImageWrapper maxWidth={262}>
                  <Img fluid={data.slabWoman.childImageSharp.fluid} />
                </ImageWrapper>
              </Parallax>
            </div>
          </div>
        </div>
      </Slab>
      <Slab className="bg-grey relative">
        <Subheading>
          Screen patients to reduce the transmission of HBV infection
        </Subheading>
        <Paragraph className="text-small text-black">
          <abbr>CHB</abbr> patients who are unaware of their infection are at
          risk for transmitting the virus to others.<sup>6</sup> Screen at-risk
          patients to:
        </Paragraph>
        <Paragraph className="text-small text-black">
          <Strong>Identify chronically infected patients</Strong> and allow for
          intervention to prevent transmission
        </Paragraph>
        <Paragraph className="text-small text-black">
          <Strong>Identify unprotected patients</Strong> and link them to{" "}
          <abbr>HBV</abbr> vaccination<sup>9</sup>
        </Paragraph>
        <div className="pt-8 pb-4">
          <Link
            to="/hepatitis-b-screening-diagnosis#vaccination"
            className="text-green underline font-bold antialiased"
            tracking={tracker(data.viewCdcRecommendations)}
          >
            View CDC recommendations for HBV vaccination
          </Link>
        </div>
      </Slab>
      <Slab>
        <div className="md:flex">
          <div className="w-full md:w-2/5">
            <EmsIcon className="pb-6" />
            <Subheading className="w-full">
              Chronic hep B can cause serious complications
            </Subheading>
            <Paragraph className="lg:pr-24">
              Patients who don’t experience symptoms may not recognize
              appropriate disease management in avoiding serious consequences.
            </Paragraph>
          </div>
          <div className="w-full md:w-3/5 ">
            <ImageWrapper maxWidth={585}>
              <Img
                fluid={data.calloutDesktop.childImageSharp.fluid}
                className="hidden md:block"
                alt="Up to 30 percent of people with CHB develop cirrhosis, HCC, or liver failure. 25 percent die prematurely of these complications"
              />
              <Img
                fluid={data.calloutMobile.childImageSharp.fluid}
                className="block md:hidden sm:mx-16 mt-12 mb-4"
                alt="Up to 30 percent of people with CHB develop cirrhosis, HCC, or liver failure. 25 percent die prematurely of these complications"
              />
            </ImageWrapper>
          </div>
        </div>
      </Slab>
      <Slab className="bg-grey-dark">
        <h3 className="text-white font-bold antialiased">
          CHB disease progression
        </h3>
        <div className="p-16 md:p-4">
          <ImageWrapper maxWidth={949}>
            <Img
              fluid={data.progressionChartHorizontal.childImageSharp.fluid}
              className="hidden md:block"
              alt="Chart showing CHB progression"
            />
          </ImageWrapper>
          <ImageWrapper maxWidth={343}>
            <Img
              fluid={data.progressionChartVertical.childImageSharp.fluid}
              className="block md:hidden"
              alt="Chart showing CHB progression"
            />
          </ImageWrapper>
        </div>
        <Footnotes className="mt-4">
          <div className="text-white">
            Percentages in the figure above are 5-year cumulative incidence
            rates.<sup>11</sup>
            <br /> <sup>b</sup>Chronic infection is defined as HBsAg+ for &ge;6
            months.<sup>12</sup>
            <div className="h-4"></div>
            <Abbr term="HBsAg" title="hepatitis B surface antigen." />
          </div>
        </Footnotes>
      </Slab>
      <Slab>
        <div className="md:flex">
          <div className="w-full md:w-2/3">
            <Subheading className="w-full">
              Risk factors associated with cirrhosis and HCC<sup>13,14</sup>
            </Subheading>
            <RiskFactorsTable className="w-full" />
            <AbbrList>
              <Abbr term="ALT" title="alanine aminotransferase" />
              <Abbr term="AFP" title="alpha-fetoprotein" />
              <Abbr term="BCP" title="basal core promoter" />
              <Abbr term="CHB" title="chronic hepatitis B" />
              <Abbr term="HBeAg" title="hepatitis B e antigen" />
              <Abbr term="HBsAg" title="hepatitis B surface antigen" />
              <Abbr term="HCC" title="hepatocellular carcinoma" />
              <Abbr term="HCV" title="hepatitis C virus" />
              <Abbr term="HDV" title="hepatitis D virus" />
              <Abbr term="HIV" title="human immunodeficiency virus" />
              <Abbr term="PC" title="precore." />
            </AbbrList>
          </div>
          <div className="w-full md:w-1/3">
            <div className="py-4">
              <ClipboardCard
                className="bg-green text-white md:ml-4 pl-6 pr-6"
                iconColor="white"
                headline={
                  <span className="text-white">Are your patients at risk?</span>
                }
                content={
                  <>
                    <BlockQuote
                      attribution="-Brian J. McMahon, MD"
                      cite={
                        <span>
                          The American Journal of Medicine<sup>15</sup>
                        </span>
                      }
                      rqBottom={32}
                      noIndent
                    >
                      &ldquo;Implementing steps to identify, follow, refer, and
                      [appropriately] treat persons with CHB infection by all
                      primary care and specialist healthcare providers can have
                      a major impact on reducing the occurrence of HCC and
                      cirrhosis in infected persons.&rdquo;
                    </BlockQuote>
                    <div className="py-4">
                      <Link
                        className="text-white underline font-bold antialiased"
                        to="/hepatitis-b-screening-diagnosis"
                        tracking={tracker(data.seeScreeningProtocols)}
                      >
                        See screening protocols
                      </Link>
                    </div>
                  </>
                }
              />
            </div>
          </div>
        </div>
      </Slab>
      <HorizontalRule />
      <Slab>
        <NextPage
          to="/hepatitis-b-screening-diagnosis"
          tracking={tracker(data.howToScreenPatients)}
        >
          How to Screen your patients for HBV
        </NextPage>
        <Subheading level={3}>
          Information for healthcare professionals
        </Subheading>
        <div className="flex flex-col md:flex-row ">
          <div className="md:w-3/5">
            <AssetCardGroup>
              <ScreeningGuidelines
                className="md:w-3/4"
                tracking={tracker(data.downloadTheGuide)}
              />
            </AssetCardGroup>
            <AbbrList className="pt-8">
              <Abbr
                term="AASLD"
                title="American Association for the Study of Liver Diseases"
              />
              <Abbr term="ACP" title="American College of Physicians" />
              <Abbr
                term="CDC"
                title="Centers for Disease Control and Prevention"
              />
              <Abbr
                term="USPSTF"
                title="U.S. Preventive Services Task Force."
              />
            </AbbrList>
            <References>
              <ReferenceItem>
                Abara W, Qaseem A, Schillie S, et al. Hepatitis B vaccination,
                screening, and linkage to care: best practice advice from the
                American College of Physicians and the Centers for Disease
                Control and Prevention. <em>Ann Intern Med</em>.
                2017;167(11):794-804
              </ReferenceItem>
              <ReferenceItem>
                Cohen C, Holmberg SD, McMahon BJ, et al. Is chronic hepatitis B
                being undertreated in the United States? <em>J Viral Hepat</em>.
                2011;18:377-383.
              </ReferenceItem>
              <ReferenceItem>
                Centers for Disease Control and Prevention. Asian Americans and
                Pacific Islanders and chronic hepatitis B. CDC website.
                https://www.cdc.gov/hepatitis/populations/api.htm. Last reviewed
                August 27, 2019. Accessed October 15, 2019.
              </ReferenceItem>
              <ReferenceItem>
                Gish R, Cohen C, Block J, et al. Data supporting updating
                estimates of the prevalence of chronic hepatitis B and C in the
                United States. <em>Hepatology</em>. 2015;62:1339-1341.
              </ReferenceItem>
              <ReferenceItem>
                Kowdley K, Wang C, Welch S, et al. Prevalence of chronic
                hepatitis B among foreign-born persons living in the United
                States by country of origin. <em>Hepatology</em>.
                2012;56:422-433.
              </ReferenceItem>
              <ReferenceItem>
                Centers for Disease Control and Prevention. MMWR Morb Mortal
                Wkly Rep. 2008.
              </ReferenceItem>
              <ReferenceItem>
                Centers for Disease Control and Prevention. Hepatitis B FAQs for
                the public. Updated April 30, 2018.
                https://www.cdc.gov/hepatitis/hbv/bfaq.htm. Last reviewed
                September 10, 2019. Accessed October 15, 2019.
              </ReferenceItem>
              <ReferenceItem>
                World Health Organization. Hepatitis B.
                https://www.who.int/news-room/fact-sheets/detail/hepatitis-b.
                Updated July 18, 2019. Accessed October 15, 2019.
              </ReferenceItem>
              <ReferenceItem>
                Terrault N, Lok A, McMahon B, et al. Update on prevention,
                diagnosis, and treatment of chronic hepatitis B: AASLD 2018
                hepatitis B guidance. <em>Hepatology</em>. 2018;67(4):1560-159.
              </ReferenceItem>
              <ReferenceItem>
                World Health Organization (WHO). Guidelines for the prevention,
                care and treatment of persons with chronic hepatitis B
                infection. Geneva, Switzerland: WHO Press; 2015.
              </ReferenceItem>
              <ReferenceItem>
                Fattovich G, Bortolotti F, Donato F. Natural history of chronic
                hepatitis B: special emphasis on disease progression and
                prognostic factors. <em>J Hepatol</em>. 2008;48(2):335-352.
              </ReferenceItem>
              <ReferenceItem>
                LeFevre ML, on behalf of the United States Preventive Services
                Task Force. Screening for hepatitis B virus infection in
                nonpregnant adolescents and adults: U.S. Preventive Services
                Task Force recommendation statement. <em>Ann Intern Med</em>.
                2014;161(1):58-66.
              </ReferenceItem>
              <ReferenceItem>
                Terrault N, Bzowej N, Chang K, et al. ASLD guidelines for
                treatment of chronic hepatitis B. <em>Hepatology</em>.
                2016;63:261-283.
              </ReferenceItem>
              <ReferenceItem>
                Tong M, Pan C, Han S, et al. An expert consensus for the
                management of chronic hepatitis B in Asian Americans.{" "}
                <em>Aliment Pharmacol Ther</em>. 2018;47(8):1181-1200.
              </ReferenceItem>
              <ReferenceItem>
                McMahon, BJ. Implementing evidenced-based practice guidelines
                for the management of chronic hepatitis B virus infection.{" "}
                <em>Am J Med</em>. 2008 Dec;121(12 Suppl):S45-52.
              </ReferenceItem>
            </References>
          </div>
          <div className="w-full md:w-2/5 h-64 md:h-auto pt-8 self-end">
            <div className="z:0 -mb-64 md:-mb-16">
              <ImageWrapper maxWidth={308}>
                <Img fluid={data.footerMan.childImageSharp.fluid} />
              </ImageWrapper>
            </div>
          </div>
        </div>
      </Slab>
    </Layout>
  );
};
export default WhyScreen;
